<template>
  <div class="userLog">
    <div class="userLog_content">
      <div class="userLog_label flex">
        <el-date-picker
          v-model="searchTimeValue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="searchTime"
          :default-time="['12:00:00', '12:00:00']"
          value-format="yyyy-MM-dd hh:mm:ss"
        >
        </el-date-picker>
        <el-button type="primary" style="margin-left: 20px" @click="getLog">搜索</el-button>
      </div>
      <el-divider></el-divider>

      <el-table
        :data="tableData"
        stripe
        style="width: 100%;border: 1px solid #e8e8e8"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column prop="accessTime" label="访问时间"> </el-table-column>
        <el-table-column prop="createTime" label="操作时间"> </el-table-column>
        <el-table-column prop="username" label="账号"> </el-table-column>
        <el-table-column prop="ipAddress" label="IP地址"> </el-table-column>
        <el-table-column label="操作类型">
          <template slot-scope="scope">
            <p>{{ scope.row.type | typeToText }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="操作详情"> </el-table-column>
        <el-table-column label="操作结果">
          <template slot-scope="scope">
            <p v-show="scope.row.username" :style="{ color: scope.row.result ? '#009688' : 'red' }">
              {{ scope.row.result ? "成功" : "失败" }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination flex"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/service.js"
import utils from "@/utils/utils.js"
export default {
  data() {
    return {
      // 日期选择
      searchTimeValue: ["", ""],
      tableData: [],
      rows: 5,
      page: 1,
      total: 0,
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.rows = val
      this.getLog()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getLog()
    },
    // 选中日期
    searchTime() {
      this.page = 1
    },
    // 获取日志
    async getLog() {
      try {
        let condition = {
          pageInfo: {
            page: this.page,
            rows: this.rows,
          },
          reqData: {
            companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
            startTime: this.searchTimeValue[0],
            endTime: this.searchTimeValue[1],
            system: JSON.parse(localStorage.getItem("userInfo")).system,
          },
        }
        const res = await api.getUserLog(condition)
        if (res.data.code == 200 && (res.data.result.list || []).length > 0) {
          let list = res.data.result.list
          list.forEach((item) => {
            item.accessTime = utils.format(item.accessTime, "yyyy-MM-dd HH:mm:ss")
            item.createTime = utils.format(item.createTime, "yyyy-MM-dd HH:mm:ss")
            item.result = item.result ? true : false
          })
          // 数量不足，补齐
          for (let i = 1, num = this.rows - list.length; i <= num; i++) {
            list.push({})
          }
          this.tableData = list
          this.total = res.data.result.total
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
  filters: {
    typeToText(val) {
      let list = {
        "0": "新增",
        "1": "删除",
        "2": "修改",
        "3": "查询",
      }
      return list[val]
    },
  },
  mounted() {
    this.getLog()
  },
}
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.userLog {
  padding: 10px;
  &_content {
    background-color: $bgColor;
    border-radius: 10px;
    padding: 20px;
    color: $fontColor;
    margin-bottom: 20px;
  }

  .pagination {
    justify-content: flex-end;
    margin-top: 20px;
  }

  .el-date-editor .el-range-separator {
    padding: 0;
  }
}
</style>
