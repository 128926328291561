function format(time, format) {
  var t = new Date(time)
  var tf = function(i) {
    return (i < 10 ? "0" : "") + i
  }
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
    switch (a) {
      case "yyyy":
        return tf(t.getFullYear())
      case "MM":
        return tf(t.getMonth() + 1)
      case "mm":
        return tf(t.getMinutes())
      case "dd":
        return tf(t.getDate())
      case "HH":
        return tf(t.getHours())
      case "ss":
        return tf(t.getSeconds())
    }
  })
}

function UUID(val, num = 9) {
  let id = val
  // 生成随机公司id
  var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  for (var i = num; i > 0; --i) {
    id += str[Math.floor(Math.random() * str.length)]
  }
  return id
}

//字符串转base64
function encode(str) {
  // 对字符串进行编码
  var encode = encodeURI(str)
  // 对编码的字符串转化base64
  var base64 = btoa(encode)
  return base64
}

// base64转字符串
function decode(base64) {
  // 对base64转编码
  var decode = atob(base64)
  // 编码转字符串
  var str = decodeURI(decode)
  return str
}

// 秒数转格式
function formatSecToDate(sec) {
  if (!sec) {
    return "-"
  }
  if (sec == "--") {
    return "00:00:00"
  }
  var min = Math.floor(sec % 3600) //分钟
  return Math.floor(sec / 3600) + ":" + Math.floor(min / 60) + ":" + (sec % 60)
}

export default {
  format: format,
  UUID: UUID,
  encode: encode,
  decode: decode,
  formatSecToDate: formatSecToDate,
}
